/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
                var scrollAdjustment = 116;
                // if($(window).width() < 1200 ) {
                //     scrollAdjustment = 172;
                // } else {
                //     scrollAdjustment = 16;
                // }

                $(window).load( function() {
                    imagesLoaded( '.masonry', function() {
                        $('.future .grid').isotope({
                            // options
                            itemSelector: '.grid-item',
                            isOriginTop: false, // was false
                            masonry: {
                                gutter: '.gutter-sizer'
                            }
                        });
                        $('.past .grid').isotope({
                            // options
                            itemSelector: '.grid-item',
                            isOriginTop: true,
                            masonry: {
                                gutter: '.gutter-sizer'
                            }
                        });

                        $('.past .grid').infinitescroll({
                            itemSelector: ".grid-item",
                            nextSelector: "div.posts a:first",
                            navSelector: "div.posts",
                            path: ['/json/posts/latest/','/'],
                            dataType: 'json',
                            appendCallback: false
                        }, function(json, opts) {
                            var htm = '';
                            $.each(json.data, function( pos, item ) {
                                htm += '<div class="wow fadeInUp grid-item ' + item.cssClasses.join(' ') + '">';
                                htm += '<a href="' + item.permaLink + '">';
                                htm += '<section class="post' + item.categories + '">';
                                htm += '<p class="meta">' + item.postDate + ' - ' + item.expiratorDate + '</p>';
                                htm += '<p class="desc">' + item.title + '</p>';
                                htm += '<figure>' + item.thumbNail + '</figure>';
                                htm += '</section>';
                                htm += '</a>';
                                htm += '</div>';
                            });
                            var items = jQuery(htm);
                            var iso = $('.past .grid');
                            iso.isotope('insert', items);
                            imagesLoaded(iso, function(instance) {
                                iso.isotope('layout');
                            });
                        });
                        
                    });
                    // var defaults = {
                    //     reset: true,
                    //     viewport: document.getElementById('future')
                    // };
                    //
                    // var myReveal = {
                    //     over:   '0.8s',
                    //     vFactor: '0.1',
                    //     move: '50px',
                    //     easing: 'ease',
                    //     scale: {
                    //         direction: 'up',
                    //         power: '0%'
                    //     }
                    // };
                    //
                    // window.sr =
                    // new scrollReveal( defaults )
                    // .reveal( '.reveal', myReveal )
                    // .init();
                    new WOW().init();
                });


                // Variables
                var $pres = $('#present');
                var $menu = $('figure.menu');
                var $window = $(window);

                //Main menu toggle
                $('header.menu p').click( function() {
                    $('.off-canvas').toggleClass('open');
                    $('header.menu p').toggleClass('active');
                    $('body').toggleClass('oh');
                });

                // NEW MENU
                // $('header.menu2').click( function() {
                //     $(this).toggleClass('open');
                //     $('body').toggleClass('oh');
                // });

                // Close menu


                // Menu animation
                // Open menu
                $menu.click( function() {
                    $menu.addClass('open');
                });
                // Close menu
                $('.menu #close').click(function(e){
                    e.stopPropagation();
                    $('.menu').removeClass('open');
                });

                // Filters
                $('.main-nav a').click( function(e) {
                    e.stopPropagation();
                    e.preventDefault();
                    var $thisID = $(this).attr('id');
                    var $thisClass = $(this).attr('Class');

                    // Helpers
                    //console.log('#' + $thisID);
                    //console.log('.' + $thisClass);

                    // Add remove class for post
                    $('.post').removeClass('active');
                    $('.post').not('.' + $thisID).addClass('active');

                    // Add remove class for link
                    $('.main-nav a').removeClass('active');
                    $(this).addClass('active');

                    // Remove everything if clicked a second time
                    if($thisClass === 'active' || $thisID === 'all') {
                        $('.main-nav a').removeClass('active');
                        $('.post').removeClass('active');
                        $('.main-nav a#all').addClass('active');
                    }

                    //scroll to position
                    setTimeout( function(){
                        // $('html, body').animate({
                        //     scrollTop: $('.' + $thisID + ':visible:first').offset().top
                        // }, 1000);
                        // if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
                        //     window.scrollTo($('.' + $thisID + ':visible:first').offset().top,0) // first value for left offset, second value for top offset
                        // }else{
                        //     $('html,body').animate({
                        //         scrollTop: $('.' + $thisID + ':visible:first').offset().top,
                        //     }, 800, function(){
                        //         $('html,body').clearQueue();
                        //     });
                        // }
                        $('html, body').animate({scrollTop: $('.' + $thisID + ':visible:first').offset().top -79 }, 800); // 79 = height of header + baseline
                    },400);
                });

                // // image gallery
                //
                // $('.gallery-item').click( function(e) {
                //     var $thisClass = $(this).attr('Class');
                //     e.preventDefault();
                //     $('.gallery-item').removeClass('active');
                //
                //     if($thisClass === 'gallery-item active') {
                //         $('.gallery-item').removeClass('active');
                //     } else {
                //         //console.log($(this));
                //         $(this).addClass('active');
                //     }
                // });

                // Article toggle-img
                $('section.body .alignright, section.body .alignleft, section.body .aligncenter, section.body .alignnone ').click( function(){
                    $(this).toggleClass('big');
                });

                // Toggle fakegrid
                // Press g for toggling grid
                $(document).keypress( function(e){
                    if(e.keyCode === 103) {
                        $('#fakegrid').toggle();
                    }
                });

                // $('.post').css({
                //     opacity: 0
                // });
                // $('#future .post').each(function(i) {
                //     $(this).delay((i++) * 200).fadeTo(1000, 1);
                // });
                // $('#past .post').each(function(i) {
                //     $(this).delay((i++) * 200).fadeTo(1000, 1);
                // });

                $('.grid-item a').click( function(e){
                    //e.preventDefault();
                    $('body').addClass('exit-left');
                });

                // Clock ——————————————————————————————————————————————————————————————————————————————

                // Get the time
                var hours = new Date().getHours();
                var minutes = new Date().getMinutes();
                var seconds = new Date().getSeconds();

                // Check initial starting position
                var hdegree = (hours * 30) + (minutes * 0.5);
                var mdegree = minutes * 6;
                var sdegree = seconds * 6;

                // Set initial starting position
                $(document).ready( function(){
                    $('.clock-hour').css({ transform: 'rotate(' + hdegree + 'deg)' });
                    $('.clock-minute').css({ transform: 'rotate(' + mdegree + 'deg)' });
                    $('.clock-second').css({ transform: 'rotate(' + sdegree + 'deg)' });
                });

                window.onscroll = function (e)
                {
                    $('section.clock').removeClass('ticking');
                    //var theta = $(window).scrollTop();
                    var $future = $('#future');
                    var $window = $(window);
                    var scrollPos = -$(document).scrollTop() + ($future.height() - ($window.height() / 2) + scrollAdjustment); // 28 to align lines with present
                    // console.log(theta);
                    $('.clock-hour').css({ transform: 'rotate(' + scrollPos / 60 + 'deg)' });
                    $('.clock-minute').css({ transform: 'rotate(' + scrollPos + 'deg)' });
                    $('.clock-second').css({ transform: 'rotate(' + scrollPos + 'deg)' });
                    //console.log(scrollPos);
                };

                // Turn time with scrollposition if scrolling
                // Else keep time ticking true to real time
                $(document).ready( function(){
                    setInterval (function(){
                        $('.alert section.clock').addClass('ticking');
                        sdegree += 6;
                        $('.alert section.clock .clock-second').css({ transform: 'rotate(' + sdegree + 'deg)' });
                        var secondsNow = new Date().getSeconds();

                        if ( secondsNow === 0 ){
                            hdegree += 0.5;
                            mdegree += 6;
                            $('.clock-hour').css({ transform: 'rotate(' + hdegree + 'deg)' });
                            $('.clock-minute').css({ transform: 'rotate(' + mdegree + 'deg)' });
                        }
                    }, 1000);
                });

                // End clock ———————————————————————————————————————————————————————————————————————————

            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
                var $past = $('#past');
                var $future = $('#future');
                var $window = $(window);
                var scrollAdjustment = 116;
                // if($(window).width() < 1200 ) {
                //     scrollAdjustment = 72;
                // } else {
                //     scrollAdjustment = 16;
                // }

                //Isotope
                $(window).load( function() {
                    // Initial scrollPos on page load
                    $(document).scrollTop($future.height() - ($window.height() / 2) + scrollAdjustment); // 28 to align lines with present
                });

                // set width to filter menu ul
                $(document).ready( function() {
                    var ulWidth = 0;
                    $('.main-nav ul li').each( function() {
                        ulWidth += $(this).outerWidth(true);
                        //console.log(ulWidth);
                    });
                    $('.main-nav ul').css({width: ulWidth + 24 + 'px'}); // 24 to add some margin
                });

                /*Auto scroll on page load
                // setTimeout( function() {
                //     $('body, html').animate({
                //         scrollTop: '0px'
                //     }, 40000);
                //     // Stop the animation if the user scrolls. Defaults on .stop() should be fine
                //     $('body, html').bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(e){
                //         if ( e.which > 0 || e.type === "mousedown" || e.type === "mousewheel"){
                //             $('body, html').stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
                //         }
                //     });
                // },2000);*/

                // // Start scrolling if user is inactive
                // var timeoutid = 0;
                // $("body, html").mousemove(function() {
                //     clearTimeout(timeoutid);
                //     timeoutid = setTimeout(function(){
                //         $('body, html').animate({
                //             scrollTop: '0px'
                //         }, 40000);
                //         // Stop the animation if the user scrolls. Defaults on .stop() should be fine
                //         $('body, html').bind("scroll mousedown DOMMouseScroll mousewheel keyup", function(e){
                //             if ( e.which > 0 || e.type === "mousedown" || e.type === "mousewheel"){
                //                 $('body, html').stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
                //             }
                //         });
                //         , 5000);
                //     });

                // Timeout test
                // setTimeout( function(){
                //     $(document).scrollTop($future.height() - ($window.height() / 2));
                // }, 2000);

                // Scroll when clicking arrows
                $('p.up').click( function() {
                    $('body, html').animate({
                        scrollTop: '0px'
                    }, 5000);
                });

                $('p.down').click( function() {
                    $('body, html').animate({
                        scrollTop: $(document).height()
                    }, 5000);
                });

                // Inject scrollPos in html
                $(document).scroll( function() {
                    var $future = $('#future');
                    var $window = $(window);
                    //console.log($(document).scrollTop());
                    var scrollPos = -$(document).scrollTop() + ($future.height() - ($window.height() / 2) + scrollAdjustment); // 28 to align lines with present
                    //console.log(scrollAdjustment);
                    $('.scrollPos').html(scrollPos);

                    // Stop jqery scrolling if user scroll
                    //$('body, html').stop();
                });

            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
